import { FieldDefinitionsDto } from "api-shared";
import ActionItemDialog, { IActionItemDialogProps } from "../../components/dialogues/ActionItemDialog";
import TableColumnConfigForm from "./TableColumnConfigForm";

interface ITableColumnConfigDialogProps extends Pick<IActionItemDialogProps, "open" | "onClose" | "translate"> {
    onSave: IActionItemDialogProps["onPrimary"];
    columns: string[];
    updateColumns: (newColumns: string[]) => void;
    fieldDefinitions: FieldDefinitionsDto;

    /** Names of the columns that should be disabled, i.e. unable to be removed. */
    disabledColumns: string[];

    /**
     * Optionally provide a prefix for the column name translation. This allows namespacing the field translations for a given entity.
     * Example: `lastModificationAt` exists on both `Measure` and `Idea`, but the respective column names have different translations.
     */
    translationPrefix?: string;
}

const TableColumnConfigDialog = ({
    open,
    onClose,
    onSave,
    columns,
    disabledColumns,
    translate,
    updateColumns,
    fieldDefinitions,
    translationPrefix,
}: ITableColumnConfigDialogProps) => {
    return (
        <ActionItemDialog
            open={open}
            onClose={onClose}
            item="columns"
            action="configure"
            primary="apply"
            onPrimary={onSave}
            title={translate("change_columns")}
            translate={translate}
            maxWidth="md"
        >
            <TableColumnConfigForm
                columns={columns}
                disabledColumns={disabledColumns}
                updateColumns={updateColumns}
                fieldDefinitions={fieldDefinitions}
                translate={translate}
                translationPrefix={translationPrefix}
            />
        </ActionItemDialog>
    );
};

export default TableColumnConfigDialog;
